<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="700px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" label-width="150px" :rules="rules">
                <el-form-item label="代理商/机构编号" prop="agentNo">
                    <el-select style="width:100%" v-model="agentNo" filterable remote reserve-keyword @change="getAgentNo"
                        placeholder="可根据代理商/机构编号模糊查询" :remote-method="remoteMethod" :loading="loading">
                        <el-option v-for="item in options" :key="item.id" :label="item.agentNo" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="代理商/机构名称" prop="agentName">
                    {{ saveData.agentName }}
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitCheck">确定新增</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { SpecialApi, FinancialToolsApi } from '@/api';
export default {
    name: "CustomForm",
    props: {
        dicts: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isAdd: {
            type: Boolean,
            default: true,
        },
        selectRow: {
            type: Object,
            default: function () {
                return {}
            },
        },
        onFresh: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: '',
            saveData: {},
            open: false,
            rules: {
                agentNo2: [{ required: true, message: '请选择代理/机构编号', trigger: 'change' }],
            },
            options: [],
            agentNo: '',
            loading: false
        }
    },
    watch: {
        onFresh: function (value) {
            this.open = value;
            if (value) {
                this.resetData();
            }
        }
    },
    mounted() {
    },
    methods: {
        async remoteMethod(query) {
            if (query != '') {
                let res = await FinancialToolsApi.suspendOrder.queryAgentInfo(query)
                if (res.success) {
                    this.options = res.data
                }
            } else {
                this.options = []
            }
        },
        submitCheck() {
            if(!this.saveData.agentNo){
                this.Message.error('请选择代理商\机构');
                return
            }
            this.submitForm()
        },
        async resetData() {
            if (this.isAdd) {
                this.title = "新增";
                this.saveData = {};
            }
        },
        async submitForm() {
            let result = (this.isAdd) ?
                (await SpecialApi.hideDataAgentConfig.save(this.saveData)) :
                (await SpecialApi.hideDataAgentConfig.edit(this.saveData.id, this.saveData));
            if (result.success) {
                this.Message.success(this.isAdd ? '添加成功' : '修改成功');
                this.saveData.agentName = ''
                this.saveData.agentNo = ''
                this.agentNo = ''
                this.commitOnClose();
            } else{
                this.Message.error(result.message)
            }
        },
        cancel() {
            this.saveData = {};
            this.saveData.agentName = ''
            this.saveData.agentNo = ''
            this.agentNo = ''
            this.commitOnClose();
        },
        commitOnClose() {
            this.$emit('on-close');
        },
        getAgentNo(item) {
            this.saveData.agentName = item.userName
            this.saveData.agentNo = item.agentNo
            this.agentNo = item.agentNo
        }
    }
};
</script>

<style scoped></style>
